.navBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 20px;
  padding: 2px 4px;
  position: relative;
}

.navBar-mobile {
  display: none;
}

.navBrand img {
  height: 70px;
}

.navHamburger {
  background-color: #fff;
  border: solid 1px #888;
  border-radius: 7px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  margin: 0 20px;
  padding: 5px 10px;
}

.navHamburger:hover {
  background-color: #ff9406;
  border: none;
  border-radius: 7px;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  transition-duration: .8s;
}

.navLink {
  background-color: transparent;
  color: #000;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 1.5rem;
  text-shadow: 0 2px 0 rgba(255, 255, 255, .5);
}

.navLink:hover {
  color: #ff9406;
  text-decoration: none;
  transition-duration: .8s;
}

.nav-mobile {
  flex-direction: column;
}

@media (max-width:991px) {
  .navBrand img {
    height: 60px;
  }

  .navLink {
    font-size: 12px;
    padding: 10px 15px;
  }
}

@media (max-width:767px) {
  .navBrand img {
    height: 50px;
  }

  .navLink {
    font-size: 11px;
    padding: 10px 5px;
  }
}

@media (max-width:575px) {
  .navBar {
    display: none;
  }

  .navBar-mobile {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 20px;
    padding: 2px 4px;
    position: relative;
  }

  .navLink {
    font-size: 14px;
    padding: 10px 1.5rem;
  }
}