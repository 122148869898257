.DesignHero {
  background-image: url("https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Techie_banner_5.jpg");
}

.ExperienceHero {
  background-image: url("https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Techie_banner_3.jpg");
}

.IndexHero {
  background-image: url("https://bryandoconnor.s3.us-west-2.amazonaws.com/images/BDO_Home_Banner.gif");
}

.SoftwareHero {
  background-image: url("https://bryandoconnor.s3.us-west-2.amazonaws.com/images/Techie_banner_1.jpg");
}

.Hero {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 200px 20px;
}

@media (max-width:1199px) {
  .Hero {
    background-size: 100%;
    padding: 150px 20px;
  }
}

@media (max-width:991px) {
  .Hero {
    background-size: 100%;
    padding: 100px 0px;
  }
}

@media (max-width:767px) {
  .Hero {
    background-size: 100%;
    padding: 50px 0px;
  }
}

@media (max-width:575px) {
  .Hero {
    background-size: 100%;
    padding: 50px 0px;
  }
}

.hero-banner-text {
  font-size: 100px;
  font-weight: 700;
  text-align: center;
  text-shadow: 3px 3px 5px black;
}

@media (max-width:1199px) {
  .hero-banner-text {
    font-size: 80px;
  }
}

@media (max-width:991px) {
  .hero-banner-text {
    font-size: 60px;
  }
}

@media (max-width:767px) {
  .hero-banner-text {
    font-size: 40px;
  }
}

@media (max-width:575px) {
  .hero-banner-text {
    font-size: 30px;
  }
}