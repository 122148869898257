.footer {
  background-color: #000;
  padding: .625rem;
}

p.footer {
  padding: .625rem;
}

.footLink {
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
}

.footLink:hover {
  color: orange;
  text-decoration: none;
  transition-duration: .8s;
}

@media (max-width:575px) {
  .footLink {
    font-size: 15px;
  }
}